<template>
  <div>
    <div class="banner">
        <img :src="ossurl+iconhttpParams" alt="" class="mainpic">
    </div>
    <div class="activ-list">
      <div class="letter" v-for="(item , index) in classList" :key="index" @click="toCreateActivity(item.pichttp,item.sno)">
        <img :src="ossurl+item.pichttp" />
        <div class="pic-tit"
             :style="`background:${colorprimary}`">{{item.stitle}}</div>
      </div>
      <div class="letter" @click="toCreateActivity(iconhttpParams,'')">
        <img src="../../../../assets/abt/img/add1.jpg" alt="" class="headpic">
      </div>
    </div>
    <div class="tips"
         :style="`backgroundColor:${colorprimary}`"
	 @click="activitylist">我的邀请函</div>
  </div>
</template>
<script>
import { selectKJFL, selectPage } from '@/api/abt/customerOperation/common'
import { getStorage } from '@/lib/util'
export default {
  data () {
    return {
      ossurl: '',
      tagcodequery: '',
      iconhttpquery: '',
      tagnamequery: '',
      classList: [],
      colorprimary: ''
    }
  },
  created () {
    this.ossurl = getStorage('ossurl', '')
    this.colorprimary = getStorage('theme', '')
    this.tagcodeParams = this.$route.query.tagcode
    this.iconhttpParams = this.$route.query.iconhttp
    this.tagnameParams = this.$route.query.tagname
    this.getData()
  },
  methods: {
    getData (item, index) {
      let data = {
        page: 1,
        size: 100,
        flag: '0',
        btagcode: '5',
        stagcode: this.tagcodeParams
      }
      selectPage(data).then(res => {
        this.classList = res.data.data.rows
      })
    },
    activitylist () {
      this.$router.push('/invitationletter/activitylist')
    },
    toCreateActivity (val1, val2) {
      this.$router.push(
        { name: 'createactivity', query: { pichttp: val1, sno: val2, stagcode: this.tagcodeParams } }
      )
    }
  }
}
</script>
<style lang="stylus" scoped>
.activ-list
  padding 10px 10px
.letter
  float left
  width 50%
  height 300px
  padding 10px 10px
  position: relative
.mainpic
  width: 100%;
.pic-tit
  width: 60%;
  height: 0.8rem;
  position: absolute;
  bottom: 10px;
  right: 10px;
  // background: color-primary;
  border-top-left-radius: 0.5rem;
  font-size: 0.35rem;
  color: white;
  text-align: center;
  line-height: 0.7rem;
  padding: 10px 10px;
.tips
  position: fixed;
  right: 0;
  bottom: 3rem;
  width: 2.25rem;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: right;
  padding: 0 0.1rem;
  background-color: color-primary;
  color: white;
  font-weight: bold;
  font-size: 0.35rem;
  border-bottom-left-radius: 1rem;
  -moz-border-radius-bottomleft: 1rem;
  -webkit-border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
  -moz-border-radius-topleft: 1rem;
  -webkit-border-top-left-radius: 1rem;
</style>
